.info-window {
  padding: 3px 0;

  .sub-header {
    padding: 3px 0 3px;
    font-size: 11px;
    color:#888;
  }

  .location-link-container {
    display: flex;
    align-items: center;
    color: #bdc3c7;
    font-size: 11px;
    margin-top: 4px;
    margin-bottom: 6px;

    a {
      text-decoration: none;
      color: #bdc3c7;

      &:hover {
        color: #3498db;
        text-decoration: underline;
      }

      i {
        color: #3498db;
      }
    }
  }
}
