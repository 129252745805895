.container {
  width: 100%;
}
.wrapper {
  padding-top: 100%;
  position: relative;
}
.wrapper > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.thumbnail {
  transition-duration: 0.1s;
  background: no-repeat center center;
  background-size: cover;
  cursor: pointer;
}
.thumbnail,
.thumbnail::before,
.thumbnail::after {
  transition-duration: .1s;
}
.thumbnail:before {
  content: "";
  display: block;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  box-shadow: 0 0 3px 0 rgba(255, 255, 255, 0.1);
  transition-duration: .1s;
}
.thumbnail:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 0 16px 28px;
  border-color: transparent transparent transparent #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -16px 0 0 -11px;
  transition-duration: .1s;
}
.thumbnail:hover::before,
.thumbnail:hover::after {
  transform: scale(1.05);
}
.thumbnail:hover::before {
  background-color: rgba(20, 20, 20, 0.95);
}
