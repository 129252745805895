.info-window {
  padding: 3px 0;
}
.info-window .sub-header {
  padding: 3px 0 3px;
  font-size: 11px;
  color: #888;
}
.info-window .location-link-container {
  display: flex;
  align-items: center;
  color: #bdc3c7;
  font-size: 11px;
  margin-top: 4px;
  margin-bottom: 6px;
}
.info-window .location-link-container a {
  text-decoration: none;
  color: #bdc3c7;
}
.info-window .location-link-container a:hover {
  color: #3498db;
  text-decoration: underline;
}
.info-window .location-link-container a i {
  color: #3498db;
}
