@import "../global/wf-variables";

.wf-item-statistics {
  min-width: 100px;
  min-height: 50px;

  &.equalWidths {
    min-height: 30px;

    .totals {
      display: block;
    }

    .total-item {
      min-width: 50px;
    }
  }

  &.smallSize {
    .total-item {
      padding: 0 10px;

      .total-value {
        font-size: 13px;
        font-weight: 600;
      }

      .total-title {
        font-weight: 400;
        font-size: 11px;
        padding-bottom: 2px;
      }
    }
  }

  .total-item {
    text-align: center;
    padding: 10px 30px;
    min-width: 120px;

    .total-value {
      font-weight: bold;
    }

    .total-title {
      font-size: 14px;
      font-weight: bold;
      padding-bottom: 2px;
      color: @wf_black_dark;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 116px;
    }

    .total-subTitle {
      color: @wf_gray;
    }
  }
}

.summary-statistics {
  display: flex;
  flex-wrap: wrap;

  border-bottom: none;
  flex: 1;
  justify-content: center;
  align-content: center;

  .total-item {
    width: initial!important;
    min-width: initial!important;
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
    flex-basis: 100px;
  }
}
