.container {
  display: flex;
  flex: 1;
}
.container .header {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.container .header .header-text-block {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 1px;
  margin-bottom: 10px;
}
.container .header .header-text-block .header-text {
  font-weight: 500;
  font-size: 12px;
  color: #333;
  padding-top: 2px;
  padding-bottom: 2px;
}
.container .header .header-text-block .icon {
  padding-right: 5px;
}
.container .header .sub-header {
  color: #95a5a6;
  font-weight: 500;
  font-size: 10px;
}
.container .image {
  width: 30px;
  height: 30px;
  background-position: center center;
  background-size: cover;
  margin-right: 10px;
  color: #afafaf;
  text-align: center;
  font-size: 25px;
  border-radius: 2px;
}
.container .image.flag-4-3 {
  height: 22.5px;
}
.container .external-link-text {
  font-weight: 300;
}
.container .description {
  padding-top: 5px;
  font-size: 91%;
  white-space: pre-wrap;
}
.container .link {
  text-decoration: none;
}
.container .link:hover {
  text-decoration: underline;
}
.container .link:hover .external-link {
  color: #3498db;
}
.container .external-link {
  margin-left: 10px;
  font-size: 12px;
  color: #bdc3c7;
  text-decoration: none !important;
}
.container .external-link i {
  margin-right: 5px;
}
.container .creator-box {
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 11px;
  color: #bdc3c7;
  transition: color .1s;
  display: flex;
  align-items: center;
}
.container .creator-box:hover {
  color: #95a5a6;
}
.container .creator-box span {
  padding-top: 2px;
}
.container .creator-box .part-divider {
  width: 1px;
  height: 11px;
  margin: 0 6px;
  background-color: rgba(149, 165, 166, 0.3);
  position: relative;
  top: 2px;
}
.container.placeholder .animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  height: 106px;
  position: relative;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
}
.container.placeholder .animated-background:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  border-radius: 4px;
  box-shadow: 0 0 0 4px #fff;
}
.container.placeholder .mask {
  position: absolute;
  background: #fff;
}
.container.placeholder .mask.header-top,
.container.placeholder .mask.header-bottom,
.container.placeholder .mask.subheader-bottom {
  top: 0;
  left: 60px;
  right: 0;
  height: 6px;
}
.container.placeholder .mask.header-left,
.container.placeholder .mask.subheader-left,
.container.placeholder .mask.header-right,
.container.placeholder .mask.subheader-right {
  top: 6px;
  left: 60px;
  height: 12px;
  width: 15px;
}
.container.placeholder .mask.header-bottom {
  top: 18px;
  height: 6px;
}
.container.placeholder .mask.subheader-left,
.container.placeholder .mask.subheader-right {
  top: 24px;
  height: 6px;
}
.container.placeholder .mask.header-right,
.container.placeholder .mask.subheader-right {
  width: auto;
  left: 300px;
  right: 0;
}
.container.placeholder .mask.subheader-right {
  left: 230px;
}
.container.placeholder .mask.subheader-bottom {
  top: 30px;
  height: 30px;
}
.container.placeholder .mask.content-top,
.container.placeholder .mask.content-second-line,
.container.placeholder .mask.content-third-line,
.container.placeholder .mask.content-second-end,
.container.placeholder .mask.content-third-end,
.container.placeholder .mask.content-first-end {
  top: 60px;
  left: 0;
  right: 0;
  height: 6px;
}
.container.placeholder .mask.content-top {
  height: 20px;
}
.container.placeholder .mask.content-first-end,
.container.placeholder .mask.content-second-end,
.container.placeholder .mask.content-third-end {
  width: auto;
  left: 380px;
  right: 0;
  top: 70px;
  height: 8px;
}
.container.placeholder .mask.content-second-line {
  top: 78px;
}
.container.placeholder .mask.content-second-end {
  left: 420px;
  top: 84px;
}
.container.placeholder .mask.content-third-line {
  top: 92px;
}
.container.placeholder .mask.content-third-end {
  left: 300px;
  top: 98px;
}
.container.extended-view {
  flex-direction: column;
}
.container.extended-view .header {
  display: flex;
  flex: 1;
  flex-direction: row;
}
.container.extended-view .header .header-text-block {
  margin-bottom: 0;
}
.container.extended-view .header .header-text-block .header-text {
  font-size: 15px;
  color: #444;
  padding-top: 2px;
  padding-bottom: 4px;
  line-height: 1.2em;
}
.container.extended-view .header .sub-header {
  color: #707980;
  font-weight: 400;
  font-size: 12px;
}
.container.extended-view .image {
  width: 60px;
  height: 60px;
  margin-right: 15px;
  color: #afafaf;
  font-size: 30px;
  border-radius: 4px;
}
.container.extended-view .image.icon {
  height: auto;
}
.container.extended-view .image.flag-4-3 {
  height: 45px;
}
.container.extended-view .description {
  padding-top: 20px;
  font-size: 12px;
  line-height: 1.48em;
}
.container.extended-view .creator-box {
  padding-top: 5px;
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
