.container {

  @imageWidth: 30px;
  @imageHeight: 30px;
  @imageRightMargin: 10px;

  display: flex;
  flex: 1;

  .header {
    display: flex;
    flex: 1;
    flex-direction: column;

    .header-text-block {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-top: 1px;
      margin-bottom: 10px;

      .header-text {
        font-weight: 500;
        font-size: 12px;
        color: #333;
        padding-top: 2px;
        padding-bottom: 2px;
      }

      .icon {
        padding-right: 5px;
      }
    }

    .sub-header {
      color: #95a5a6;
      font-weight: 500;
      font-size: 10px;
    }
  }

  .image {
    width: @imageWidth;
    height: @imageHeight;
    background-position: center center;
    background-size: cover;
    margin-right: @imageRightMargin;
    color: #afafaf;
    text-align: center;
    font-size: 25px;
    border-radius: 2px;

    &.flag-4-3 {
      height: (@imageWidth/(4/3)) * 1px;
    }
  }

  .external-link-text {
    font-weight: 300;
  }

  .description {
    padding-top: 5px;
    font-size: 91%;
    white-space: pre-wrap;
  }

  .link {
    text-decoration: none;
    &:hover {
      text-decoration: underline;

      .external-link {
        color: #3498db;
      }
    }
  }

  .external-link {
    margin-left: 10px;
    font-size: 12px;
    color: #bdc3c7;
    text-decoration: none !important;

    i {
      margin-right: 5px;
    }
  }

  .creator-box {
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 11px;
    color: #bdc3c7;
    transition: color .1s;
    display: flex;
    align-items: center;

    &:hover {
      color: #95a5a6;
    }

    span {
      padding-top: 2px;
    }

    .part-divider {
      width: 1px;
      height: 11px;
      margin: 0 6px;
      background-color: rgba(149, 165, 166, 0.3);
      position: relative;
      top: 2px;
    }
  }

  &.placeholder {
    @imageWidth: 60px;
    @imageHeight: 60px;
    @imageRightMargin: 15px;

    .animated-background {
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeHolderShimmer;
      animation-timing-function: linear;
      //background: #f6f7f8;
      height: @imageHeight + 46px;
      position: relative;

      background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
      background-size: 800px 104px;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: @imageWidth;
        height: @imageHeight;
        border-radius: 4px;
        box-shadow: 0 0 0 4px #fff;
      }
    }//end of: .animated-background
    .mask {
      position: absolute;

      background: #fff;

      //-----Mask positioning-----
      &.header-top,
      &.header-bottom,
      &.subheader-bottom {
        top: 0;
        left: @imageWidth;
        right: 0;
        height: 6px;
      }

      &.header-left,
      &.subheader-left,
      &.header-right,
      &.subheader-right {
        top: 6px;
        left: @imageWidth;
        height: 12px;
        width: @imageRightMargin;
      }

      &.header-bottom {
        top: 18px;
        height: 6px;
      }

      &.subheader-left,
      &.subheader-right {
        top: 24px;
        height: 6px;
      }


      &.header-right,
      &.subheader-right {
        width: auto;
        left: 300px;
        right: 0;
      }

      &.subheader-right {
        left: 230px;
      }

      &.subheader-bottom {
        top: 30px;
        height: @imageHeight - 30px;
      }

      &.content-top,
      &.content-second-line,
      &.content-third-line,
      &.content-second-end,
      &.content-third-end,
      &.content-first-end {
        top: @imageHeight;
        left: 0;
        right: 0;
        height: 6px;
      }

      &.content-top {
        height:20px;
      }

      &.content-first-end,
      &.content-second-end,
      &.content-third-end{
        width: auto;
        left: 380px;
        right: 0;
        top: 70px;
        height: 8px;
      }

      &.content-second-line  {
        top: 78px;
      }

      &.content-second-end {
        left: 420px;
        top: 84px;
      }

      &.content-third-line {
        top: 92px;
      }

      &.content-third-end {
        left: 300px;
        top: 98px;
      }
    }//end of: .mask
  }

  &.extended-view {
    @imageWidth: 60px;
    @imageHeight: 60px;
    @imageRightMargin: 15px;

    flex-direction: column;

    .header {
      display: flex;
      flex: 1;
      flex-direction: row;

      .header-text-block {
        margin-bottom: 0;

        .header-text {
          font-size: 15px;
          color: #444;
          padding-top: 2px;
          padding-bottom: 4px;
          line-height: 1.2em;
        }
      }

      .sub-header {
        color: #707980;
        font-weight: 400;
        font-size: 12px;
      }
    }

    .image {
      width: @imageWidth;
      height: @imageHeight;
      margin-right: @imageRightMargin;
      color: #afafaf;
      font-size: 30px;
      border-radius: 4px;

      &.icon {
        height: auto;
      }

      &.flag-4-3 {
        height: (@imageWidth/(4/3)) * 1px;
      }
    }

    .description {
      padding-top: 20px;
      font-size: 12px;
      line-height: 1.48em;
    }

    .creator-box {
      padding-top: 5px;
    }
  }
}

@keyframes placeHolderShimmer{
  0%{
    background-position: -468px 0
  }
  100%{
    background-position: 468px 0
  }
}
