.wf-item-statistics {
  min-width: 120px;
  min-height: 50px;
}
.wf-item-statistics.equalWidths {
  min-height: 30px;
}
.wf-item-statistics.equalWidths .totals {
  display: block;
}
.wf-item-statistics.equalWidths .total-item {
  min-width: 50px;
}
.wf-item-statistics.smallSize .total-item {
  padding: 0 10px;
}
.wf-item-statistics.smallSize .total-item .total-value {
  font-size: 13px;
  font-weight: 600;
}
.wf-item-statistics.smallSize .total-item .total-title {
  font-weight: 400;
  font-size: 11px;
  padding-bottom: 2px;
}
.wf-item-statistics .total-item {
  text-align: center;
  padding: 10px 30px;
  min-width: 120px;
}
.wf-item-statistics .total-item .total-value {
  font-size: 18px;
  font-weight: 500;
}
.wf-item-statistics .total-item .total-title {
  font-weight: bold;
  padding-bottom: 2px;
}
.wf-item-statistics .total-item .total-subTitle {
  color: #bdc3c7;
}
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}
